import React, { useEffect } from 'react';

const GoogleAdsenseMeta: React.FC = () => {
  useEffect(() => {
    // Create the meta tag
    const metaTag = document.createElement('meta');
    metaTag.name = 'google-adsense-account';
    metaTag.content = 'ca-pub-2005723257064782';  // Replace with your AdSense publisher ID

    // Append it to the head
    document.head.appendChild(metaTag);

    // Cleanup: Remove the meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return null; // This component doesn't need to render anything
};

export default GoogleAdsenseMeta;
