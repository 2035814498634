import React, { useState } from 'react';
import './Contact.css'; // Make sure to import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import bchQrCode from '../../assets/images/bchpay.png';

const DonatePage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [getdonateText, setdonateText] = useState('Copy Address');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const bchAddress = "qp8m9kndhvfs0lfptv4arcslcvz57e9725z8u5rvnf";


  const copyAddress = () => {
    navigator.clipboard.writeText(bchAddress)
      .then(() => {
        // alert('Address copied to clipboard!');
        setdonateText("Address Copied!")
      })
      .catch(() => {
        alert('Failed to copy address. Please try again.');
      });
  };

  return (
    <>
      <header className="bg-blue-600 text-white p-4 w-full shadow-md">
        <nav className="container mx-auto flex items-center justify-between flex-wrap">
          <div className="flex items-center gap-2">
            <img className='w-12 h-10' src="/eicon.png" alt="logo" />
            <NavLink to="/" className="text-xl font-semibold">
              ekindleLibrary
            </NavLink>
          </div>
          <div className="flex gap-4 items-center">
            {[
              { label: 'Home', link: '/', icon: faHome },
            ].map(({ label, link, icon }, index) => (
              <NavLink
                to={link}
                key={index}
                className={({ isActive }) =>
                  cx('flex items-center gap-2 hover:underline', { 'font-bold': isActive })
                }
              >
                <FontAwesomeIcon icon={icon} className="" /> {/* Icon */}
                {label}
              </NavLink>
            ))}
          </div>
        </nav>
      </header>

      {/* go-back */}
      <div className='md:ps-24 md: pt-4 pb-2 ps-4'>
        <button 
          onClick={() => window.history.back()} 
          className=" text-gray-600 hover:text-gray-900 transition duration-300 flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className='pe-1'/>
          Go back
        </button>
      </div>
      
      <div className=''>
        <div className="upload-form">
          <h1 style={{fontSize: 26, fontWeight: "600"}}>Small donation to keep us running</h1>
          <p style={{fontSize: 16}}>Running this website involves regular costs to maintain. This involves server cost, database cost, regular maintenance cost and domain name cost. We need your help to keep this website running for a long period of time. Kindly donate us to run this site stable for long time.</p>
          <div style={{justifyContent: "center", alignItems: "center", display: "flex", margin: '20px 0'}}>
            <img
              src={bchQrCode}
              alt="BCH QR Code"
              style={{width: "35%", height: "35%"}}
            />
          </div>
          <div style={{ margin: '10px 0', paddingBottom: 8}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <input
                type="text"
                value={bchAddress}
                readOnly
                style={{flexGrow: 1, fontSize: '16px', padding: '10px'}}
              />
          <button style={{padding: '0px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick={copyAddress}>
            {getdonateText}
          </button>
            </div>
          </div>
          <h2 style={{textAlign: "center", fontSize: 20,}}>Thanks to our donors</h2>
        </div>
      </div>

      <footer className="bg-blue-600 text-white p-4 w-full mt-auto">
        <div className="text-center">
          &copy; 2024 ekindleLibrary
        </div>
      </footer>
    </>
  );
};

export default DonatePage;
