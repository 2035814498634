import React, { useEffect, useState, useRef } from 'react';
import Card from './Card';
import './CardContainer.css';
import { RingLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { Book } from './types';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/ebook_loading.json';

interface CardContainerProps {
  selectedTags: string[];
  searchTerm: string; 
}
const ITEMS_PER_PAGE = 8;

const blockedIds = [11, 12, 13, 14]; // IDs to block

const CardContainer: React.FC<CardContainerProps> = ({selectedTags, searchTerm}) => {
  const navigate = useNavigate();
  const [bookData, setBookData] = useState<any[]>([]);
  const [filteredBooks, setFilteredBooks] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("German"); // Default language
  const [currentPage, setCurrentPage] = useState(1);

  const API_BOOKDATA_URL = "https://pub-9fe0737abb0b446db39ae7d5c3702f19.r2.dev/";
  const API_BOOKSDATA_URL = "https://workers.younus-tao-ai.workers.dev/files/";

  const handleOpenFullPage = (book: any) => {
    const bookDetails: Book = {
      title: book.title,
      description: book.details,
      readNowUrl: API_BOOKSDATA_URL + book.epubfilename,
      cover: API_BOOKDATA_URL + book.cover_image,
      authorname: book.authorname,
      rating: book.rating,
      tags: parseTags(book.tags),
      language: book.language,
      release_date: book.release_date,
      epubfilesize: book.epubfilesize,
      publishingdate: book.publishingdate
    };
    navigate('/bookdetails', { state: bookDetails });
  };

  function parseTags(tags: string): string[] {
    try {
      const parsed = JSON.parse(tags);
      if (Array.isArray(parsed)) {
        return parsed.map((tag: string) => tag.trim()).filter(tag => tag.length > 0);
      }
    } catch (e) {
      return tags.split(',').map(tag => tag.trim()).filter(tag => tag.length > 0);
    }
    return [];
  }

  const fetchBooks = (language: string) => {
    setLoading(true);
    fetch(`https://workers.younus-tao-ai.workers.dev/ebooksdata?language=${language}`)
      .then((response) => response.json())
      .then((responseJson) => {
        const sortedBooks = responseJson.result.sort((a: any, b: any) => {
          const dateA = new Date(a.release_date);
          const dateB = new Date(b.release_date);
          return dateB.getTime() - dateA.getTime(); // Sort in descending order
        });
        setBookData(sortedBooks);
        setFilteredBooks(sortedBooks); // Initially show all books
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBooks(selectedLanguage); // Fetch books with the selected language
  }, [selectedLanguage]);

  useEffect(() => {
    const filtered = bookData.filter((book) => {
      const bookTags = book.tags ? parseTags(book.tags) : [];
      const matchesTag = selectedTags.length === 0 || selectedTags.some(selectedTag => bookTags.includes(selectedTag));
      const matchesSearch = book.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
                            book.authorname.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesTag && matchesSearch;
    });
    setFilteredBooks(filtered);
    setCurrentPage(1);
  }, [selectedTags, bookData, searchTerm]);

  const totalPages = Math.ceil(filteredBooks.length / ITEMS_PER_PAGE);
  const currentBooks = filteredBooks.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  // Filter out blocked items before slicing for pagination
  // const currentBooks = filteredBooks
  //   .filter((item) => !blockedIds.includes(item.id)) 
  //   .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <div className="card-container">
      <div className="header md:pe-4">
        <p className="header-title border-b-2 border-blue-600">New Releases</p>
        <select 
          className="language-picker" 
          value={selectedLanguage} 
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="English">English</option>
          <option value="German">German</option>
        </select>
      </div>
  
      <div className="content">
        <div className="relative flex items-center">
          <div className='container justify-center'>
            {isLoading ? (
              <div className="loading-container">
                <div className="loading-view">
                  <Lottie 
                    animationData={loadingAnimation} // Use the imported variable
                    loop 
                    autoplay 
                    style={{ width: '200px', height: '200px' }} 
                  />
                </div>
              </div>
            ) : currentBooks.length === 0 ? (
              <div className="no-items-found">
                <p>No items found. Please try a different filter or language.</p>
              </div>
            ) : (
              <>
                {currentBooks.map((item: any) => (
                  <Card
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    book={item.epubfilename}
                    cover={API_BOOKDATA_URL + item.cover_image}
                    description={item.details}
                    rating={item.rating}
                    authorname={item.authorname}
                    onClick={() => handleOpenFullPage(item)}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
  
      <footer className="pagination-footer">
        <div className="pagination-controls">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </footer>
    </div>
  );
};

export default CardContainer;
